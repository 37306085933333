<template>
  <section class="login-section">
    <div class="form-box">
      <form action="">
        <h2>Login</h2>
        <div class="inputbox">
          <fa icon="envelope" />
          <input type="email" name="" id="" placeholder=" " required>
          <label for="">Email</label>
        </div>
        <div class="inputbox">
          <fa icon="lock" />
          <input type="password" name="" id="" placeholder=" " required>
          <label for="">Password</label>
        </div>
        <div class="forget">
          <label for="">
            <input type="checkbox" name="" id="">
            Ingat saya
          </label>
          <a href="#">Lupa password?</a>
        </div>
        <button class="form-button">Masuk</button>
        <div class="register">
          <p>Tidak memiliki akun? <a href="#">Daftar</a></p>
        </div>
      </form>
    </div>
  </section>
</template>

<script>

</script>

<style>
.login-section {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.form-box {
  position: relative;
  display: flex;
  width: 90%;
  height: 45rem;
  background-color: #e4e2e0;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.form-box form {
  width: 85%;
}
.form-box form h2 {
  font-size: 2.4rem;
  text-align: center;
}
.inputbox {
  position: relative;
  margin: 3rem 0;
  border-bottom: 2px solid #fff;
}
.inputbox svg {
  position: absolute;
  right: 8px;
  color: #107c5c;
  font-size: 1.4rem;
  top: 1.6rem;
}
.inputbox input {
  width: 100%;
  height: 5rem;
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 1.4rem;
  padding: 0 35px 0 5px;
}
.inputbox input:focus ~ label,
.inputbox input:not(:placeholder-shown) ~ label {
  top: -5px;
}
.inputbox label {
  position: absolute;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
  font-size: 1.4rem;
  pointer-events: none;
  transition: .25s all ease;
}
.forget {
  margin-bottom: 2rem;
  font-size: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5rem;
}
.forget label {
  display: flex;
  align-items: center;
}
.forget label input {
  margin-right: 4px;
}
.forget a {
  text-decoration: none;
  color: #2a2b26;
}
.forget a:hover {
  text-decoration: underline;
}
.form-button {
  width: 100%;
  height: 3rem;
  border-radius: 12px;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 1.3rem;
  font-weight: 600;
}
.register {
  font-size: 1.2rem;
  text-align: center;
  margin-top: 2rem;
}
.register p a {
  text-decoration: none;
  color: #2a2b26;
  font-weight: 500;
}
.register p a:hover {
  text-decoration: underline;
}

@media (min-width: 450px) {
  .form-box {
    max-width: 40rem;
  }
}
</style>